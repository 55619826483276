import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './js/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter } from 'react-router-dom';
import './media/fonts/Source_Sans_3/static/SourceSans3-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    {/* The link below is the external link icon */}
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    <App />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
