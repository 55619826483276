import React from "react";

// Contents of the Outputs div within in the ModelPage component
function ModelOutputDivContents({ modelOffline, outputValue, errorValue }) {
    return (
        <div>
            {Object.keys(outputValue).length === 0 ? (
                <div className="grid-container">
                    <div className="grid-item">Awaiting Submission</div>
                    <div className="grid-item">----</div>
                </div>
            ) : (
                <div className="grid-container">
                    <div className="grid-item">key</div>
                    <div className="grid-item">value</div>

                    {
                        Object.entries(outputValue).map(([key, value]) => (
                            <>
                                <div className="grid-item">{key}</div>
                                <div className="grid-item">{value}</div>
                            </>
                        ))
                    }
                </div>
            )}

            {
                errorValue && (
                    <p className="red" style={{ marginTop: "10px", marginBottom: "0" }}>{errorValue}</p>
                )
            }
            {
                (modelOffline &&
                    (<p className='light sm-text'>Note: Unlike other models, this model is "offline", meaning it is run directly through the page JavaScript.</p>)) ||
                (<p className='light sm-text'>Note: Models are deployed to an AWS Lambda Image. This means the first prediction triggers a cold start, which may take up to a minute.</p>)
            }

        </div >
    )
}

export default ModelOutputDivContents;