// These functions all generate json objects for the parameters of input fields
// These functions are called within models.js
// The resulting json objects are used inside ModelInputDivContents.js for rendering an input form

const textInput = (name) => {
    return {
        type: "text",
        name: name
    }
}

const imageInput = (name, width, height) => {
    return {
        type: "image",
        width: width,
        height: height,
        name: name
    }
}

const integerInput = (name, min, max) => {
    return {
        type: "integer",
        name: name,
        min: min,
        max: max
    }
}

const floatInput = (name, min, max, decimalPlaces) => {
    return {
        type: "float",
        name: name,
        min: min,
        max: max,
        decimalPlaces: decimalPlaces
    }
}

// This function generates a json object for an individual timeline experience
const timelineExperience = (label, company, date, description) => ({
    label: label,
    company: company,
    date: date,
    description: description
});

export { textInput, imageInput, integerInput, floatInput, timelineExperience };