import { React, useEffect, useState } from 'react';
import ModelInputDivContents from '../components/ModelInputDivContents';
import axios from 'axios';
import ModelOutputDivContents from '../components/ModelOutputDivContents';
import '../../style/model.css';
import { jpegBlobToBase64 } from '../data/util';
import { handleAltSubmit } from '../data/altSubmit';

// This is a generic page to display model info (title, colab link, etc.),
// and the live demo (input and output divs)
// It also handles the API call to make model predictions

function ModelPage({ modelInfo }) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])

  // Variable that tells loading spinner if request is still processing
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  // Output variables to display onto `ModelOutputDivContents` div
  const [outputValue, setOutputValue] = useState({});
  const [errorValue, setErrorValue] = useState('');




  // Method called by `ModelInputDivContents` submission 
  // click to send input data to API for model predictions
  const submitInputsToAPI = async (inputs, retryCount = 0) => {
    const maxRetries = 0;
    const retryDelay = 10000; // 3 seconds in milliseconds

    // If not all inputs are filled out, throw an error
    for (let key in inputs) {
      if (inputs[key]['value'] === null) {
        setErrorValue('Please complete all fields before submitting.')
        return;
      }
    }

    // If a certain model has altSubmit set to true, that means it does not use the convention with FormData below.
    // Instead, call altSubmit and pass in the input data for custom handling
    if (modelInfo.altSubmit) {
      await altSubmit(inputs, 0, maxRetries, retryDelay)
      return
    }
    // Structure input data into json and files
    const formData = new FormData();
    const imagePromises = [];

    await Object.entries(inputs).forEach(async ([key, value]) => {

      if (value["type"] === "image") { // add more file types as needed
        const imagePromise = jpegBlobToBase64(value["value"])
          .then((base64Image) => {
            formData.append("image/jpeg" + key, JSON.stringify(base64Image));
          })
          .catch((error) => {
            console.error(error);
          });
        imagePromises.push(imagePromise);

      } else {
        formData.append(key, JSON.stringify(value))
      }

    })
    await Promise.all(imagePromises);

    setAwaitingResponse(true);
    try {
      // Make the API request
      const response = await axios.post("https://ml-api.kailauapps.com/" + modelInfo.route, formData, {        
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setOutputValue(response.data);
      setErrorValue('');
      setAwaitingResponse(false);
    } catch (error) {
      console.error('Error sending request:', error);
      setErrorValue(`An Error Occurred. Waiting 10 seconds before next attempt (${retryCount}/${maxRetries})`)

      if (retryCount < maxRetries) {
        // Retry after delay
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        await submitInputsToAPI(inputs, retryCount + 1); // Retry the API call
      }
    }
    setAwaitingResponse(false);
  }

  // This function is an alternative to the above submitInputsToAPI that does not use the same formdata conventions
  const altSubmit = async (inputs, retryCount, maxRetries, retryDelay) => {
    setAwaitingResponse(true);
    let response = await handleAltSubmit(modelInfo.route, inputs);
    if (response.status === '200') {
      setOutputValue(response.data);
      setErrorValue('');
      setAwaitingResponse(false);
    } else {
      console.error('Error sending request:', response.error);
      setErrorValue(`An Error Occurred. Waiting 10 seconds before next attempt (${retryCount}/${maxRetries})`)

      if (retryCount < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        await altSubmit(inputs, retryCount + 1, maxRetries, retryDelay)
      }
    }
    setAwaitingResponse(false);
  }

  return (
    <div className='page-margins'>
      <h1>{modelInfo.displayName}</h1>
      <div className='model-description-area'>
        <p>{modelInfo.description}</p>
        {modelInfo.image && (
          <img src={modelInfo.image} alt={"image-" + modelInfo.route} />
        )}

      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {modelInfo.colabLink && (
          <div>
            <span className='light' style={{ fontWeight: "600" }}>View Notebook: </span>
            <a target="_blank" rel="noopener noreferrer" href={modelInfo.colabLink}>
              <img src="https://colab.research.google.com/assets/colab-badge.svg" alt="Open In Colab" />
            </a>
          </div>
        )}
        {modelInfo.externalLinks.map(item => {
          return <a target="_blank" rel="noopener noreferrer" style={{ color: "var(--light)" }} href={item.url}>{item.name}</a>
        })}
      </div>
      <div className='demo-container'>
        <div className='demo-section'>
          <h2>Inputs</h2>
          {/* Display all input fields as defined in `models.js` and connect form submission to `submitInputsToAPI` */}
          <ModelInputDivContents inputs={modelInfo.inputs} awaitingResponse={awaitingResponse} submitInputsToAPI={submitInputsToAPI} />
        </div>
        <div className='demo-section' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <h2>Outputs</h2>
          {/* Display all output labels, passed in with `outputValue` state variable */}
          <ModelOutputDivContents modelOffline={modelInfo.modelOffline} outputValue={outputValue} errorValue={errorValue} />
        </div>
      </div>
    </div>
  );
}

export default ModelPage;