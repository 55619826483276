import React from "react";

function GlobalFooter() {
    return (
        <footer>
            <p style={{ width: "100%", fontSize: '16px'}}>&copy; Dylan Kai Lau 2023</p>
            <br />
            <a className="footer-text" href='https://www.linkedin.com/in/dylan-kai-lau/' rel='noopener noreferrer' target="_blank">LinkedIn</a>
            <a className="footer-text" href='https://www.youtube.com/channel/UCwvUYyi8rkCkzSWTd3ol53A' rel='noopener noreferrer' target="_blank">YouTube</a>
        </footer>
    );
}

export default GlobalFooter;