import '../style/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import Homepage from './pages/Homepage';
import GlobalNavbar from './components/GlobalNavbar';
import { models } from './data/displayData';
import ModelPage from './pages/ModelPage';
import GlobalFooter from './components/GlobalFooter';

function App() {

  return (
    <div className='app-contents'>
      <GlobalNavbar />
      <Routes>
        {/* Homepage route */}
        <Route path="/" Component={Homepage} />

        {/* Routes for all models in `models.js` file */}
        {models.map(modelInfo => (
          <Route path={modelInfo.route} key={modelInfo.route} Component={() => <ModelPage modelInfo={modelInfo}/>} />
        ))}
        
      </Routes>
      <GlobalFooter />
    </div>

  );
}

export default App;
