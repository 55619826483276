import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import profileImage from '../../media/profile-picture.jpg'
import { models } from '../data/displayData';
import { useNavigate } from 'react-router-dom';

// Navigation bar at top of every screen
function GlobalNavbar() {

  // programatically handle site navigation
  const navigate = useNavigate();
  const onClickDropdownItem = (route) => {
    navigate(route);
    window.scrollTo(0,0);
  }

  return (
    <Navbar expand="sm" sticky="top">
      <Navbar.Brand href="/">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image src={profileImage} roundedCircle className="mr-2" style={{ width: '50px', height: '50px', alignSelf: 'center' }} />
          <Navbar.Collapse>
            <p style={{ margin: 'auto', padding: '10px' }} className='navbar-brand'>Kai Lau</p>
          </Navbar.Collapse>

        </div>
      </Navbar.Brand>

      <NavDropdown title="ML Projects" id="basic-nav-dropdown" className='navbar-link light-font'>
        {models.map(modelInfo => (
          <NavDropdown.Item key={"item" + modelInfo.route} onClick={() => onClickDropdownItem(modelInfo.route)}>{modelInfo.displayName}</NavDropdown.Item>
        ))}
      </NavDropdown>
    </Navbar>
  );
}

export default GlobalNavbar;
