import React, { useState } from 'react';
import { resizeImage } from '../data/util';
import { Button } from 'react-bootstrap';
import SliderTextField from './SliderTextField';

// Contents of the Inputs div within in the ModelPage component
// This component dynamically renders a variety of inputs (based on inputs field in `models.js`)
function ModelInputDivContents({ inputs, awaitingResponse, submitInputsToAPI }) {

    // Initialize field values based on the provided fields list
    const initialInputValues = inputs.reduce((acc, input) => {
        // Split the field into type, parts, and name using the hyphen separator
        const type = input.type;
        const name = input.name;

        // Return default values for each item
        // If input is numerical, get the minimum and maximum range
        if (type === 'integer') {
            acc[name] = { type, value: parseInt(input.min), min: parseInt(input.min), max: parseInt(input.max) };
        } else if (type === 'float') {
            acc[name] = { type, value: parseFloat(input.min), min: parseFloat(input.min), max: parseFloat(input.max), decimalPlaces: parseFloat(input.decimalPlaces) };
        } else if (type === 'image') {
            acc[name] = { type, width: input.width, height: input.height, value: null };
        } else { // default type is text / text-area
            acc[name] = { type, value: '' };
        }
        return acc;
    }, {});

    // Use React state to manage the field values
    const [inputValues, setInputValues] = useState(initialInputValues);

    // Handle changes to field values
    const handleInputChange = (inputName, newValue) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [inputName]: { ...prevValues[inputName], value: newValue },
        }));
    };

    const handleNumberChange = (inputName, inputData, newValue) => {
        if (isNaN(newValue)) {
            newValue = inputData.min;
        }
        newValue = Math.max(inputData.min, Math.min(inputData.max, newValue));

        if (inputData.type === 'float') {
            newValue = newValue.toFixed(inputData.decimalPlaces);
        }

        handleInputChange(inputName, newValue);

    }

    // Handle changes to image (resize image before updating state variable)
    const handleImageChange = async (inputName, inputData, newImage) => {
        try {
            const resizedImage = await resizeImage(newImage, inputData);
            handleInputChange(inputName, resizedImage);
        } catch (error) {
            console.log("error uploading image.");
        }
    }


    // When 'Remove' is clicked, remove image from state and from image input element
    const removeImage = (inputName) => {
        handleInputChange(inputName, '');
        const imageInput = document.getElementById("image-" + inputName);
        imageInput.value = "";
    }

    // Handle form submission button click
    const handleSubmit = event => {
        event.preventDefault();
        // console.log(inputValues);
        submitInputsToAPI(inputValues); // method call in `ModelPage.js` to send inputs to API
    };

    return (
        <div className='input-form'>
            {/* Loop through each field value and render the appropriate input */}
            {Object.entries(inputValues).map(([inputName, inputData]) => (
                <div key={inputName} className='input-field'>
                    <label className='light label'>{inputName}{inputData.type === "image" && <span className='gray'> ({inputData.width}x{inputData.height})</span>}:</label>

                    {/* If it's a text  input */}
                    {inputData.type === 'text' && (
                        <textarea
                            type="text"
                            className='text-area-input'
                            value={inputData.value}
                            onChange={e => handleInputChange(inputName, e.target.value)}
                        />
                    )}

                    {/* If it's an image input */}
                    {inputData.type === 'image' && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <input
                                id={"image-" + inputName} // id used to locate input element when "remove" is clicked
                                className='light'
                                type="file"
                                accept=".jpg, .jpeg"
                                onChange={e => handleImageChange(inputName, inputData, e.target.files[0])}
                            />
                            {inputData.value && (
                                <div className='image-preview'>
                                    <img
                                        src={URL.createObjectURL(inputData.value)}
                                        alt={`${inputName} Preview`}
                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    />
                                    <Button className='light-button' onClick={() => removeImage(inputName)}>Remove</Button>
                                </div>

                            )}
                        </div>
                    )}
                    {/* If it's an integer input */}
                    {inputData.type === 'integer' && (
                        <div className='slider-container'>
                            <input
                                type="range"
                                className='slider'
                                min={inputData.min}
                                max={inputData.max}
                                value={inputData.value}
                                onChange={e => handleInputChange(inputName, parseInt(e.target.value))}
                            />
                            <SliderTextField inputName={inputName} inputData={inputData} handleNumberChange={handleNumberChange} />
                            {/* <input type="text" value={inputData.value} className='light slider-text-input' onBlur={e => handleNumberChange(inputName, inputData, parseInt(e.target.value))} /> */}
                        </div>
                    )}
                    {/* If it's a float input */}
                    {inputData.type === 'float' && (
                        <div className='slider-container'>
                            <input
                                type="range"
                                className='slider'
                                min={inputData.min}
                                max={inputData.max}
                                step={Math.pow(10.0, -inputData.decimalPlaces)}
                                value={inputData.value}
                                onChange={e => handleInputChange(inputName, parseFloat(e.target.value))}
                            />
                            <SliderTextField inputName={inputName} inputData={inputData} handleNumberChange={handleNumberChange} />
                            {/* <input type="text" value={inputData.value} className='light slider-text-input' onChange={e => handleNumberChange(inputName, inputData, parseFloat(e.target.value))} /> */}
                        </div>
                    )}
                    {/* 
                            Add more input types here as needed:
                        */}
                </div>
            ))}
            {awaitingResponse ?
                (<div className='spinner'></div>) :
                (<Button className="light-button" onClick={handleSubmit}>Submit</Button>)
            }
        </div>
    );
}

export default ModelInputDivContents;