import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { experienceTimeline, skills, models } from '../data/displayData';
import ModelDisplayCard from '../components/ModelDisplayCard';
import '../../style/home.css';
import { Button } from 'react-bootstrap';
import resumeLink from '../../media/Dylan-Lau-Resume.pdf';

// This is the page that will be displayed at the root url
function Homepage() {
  return (
    <div className='page-margins'>
      <div>
        <h1><span className='gray' style={{fontSize: "70%"}}>(Dylan)</span> Kai Lau</h1>
        <h3>Welcome to My Machine Learning Portfolio!</h3>
        <div style={{display:"inline"}}>
          <Button href={resumeLink} target="_blank" rel="noopener noreferrer">Resume</Button>
          <Button onClick={() => {
             const projectsTitle = document.getElementById("projects");
             const targetPos = projectsTitle.getBoundingClientRect().top + window.scrollY - 90;
             window.scrollTo({
              top: targetPos,
              behavior: 'smooth'
             });
          }}>ML Projects</Button>
        </div>
        <hr />
        <div className='about-skills-split'>
          <div className='about-section'>
            <h2>My Experience</h2>
            <div className='about-cards'>
              {experienceTimeline.map(exp => (
                <div className='about-card' key={exp.label}>
                  <div className='about-card-popup'>
                    <div style={{marginBottom:"15px"}}><b>{exp.label}</b></div>
                    <div style={{marginLeft: "15px", fontSize: "16px"}}>{
                      exp.description &&
                    exp.description.split("*").map((token, index) => {
                      
                      if (index % 2 === 0) {
                        return (<span key={index}>{token}</span>)
                      } else {
                        return (<span key={index} style={{fontWeight:"700"}}>{token}</span>)
                      }
                    }, {})
                    }</div>
                    <div className="gray" style={{textAlign: "right", marginTop:"15px"}}>{exp.date}</div>
                    </div>
                  <div className='timeline-line'>
                    <div className='timeline-circle'></div>
                  </div>
                  <div>
                    <div><b>{exp.label}</b></div>
                    <div>{exp.company}</div>
                  </div>
                  <div className='gray'>{exp.date}</div>
                </div>
              ))}
            </div>
          </div>
          <div className='skills-section'>
            <h2>My Skills</h2>
            <div className='skills-div'>
              {skills.map(skill => (
                <div className='skill-card' key={skill}>{skill}</div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        <h2 id="projects">Machine Learning Portfolio</h2>
        <p style={{padding: "10px 0px"}}>
          Explore my interactive <b>machine learning</b> portfolio, featuring a range models trained with <b>TensorFlow</b>, <b>Scikit-learn</b>, or from scratch with <b>NumPy</b>. These models built onto a <b>Docker Image</b> and deployed to <b>AWS Lambda</b>.
        </p>
      </div>
      {/* All model display cards shown here */}
      {models.map(modelInfo => {
        return (
          <ModelDisplayCard key={modelInfo.route} modelInfo={modelInfo} />
        )
      })}
    </div>
  );
}

export default Homepage;
