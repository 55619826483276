import axios from "axios";
import { convertBlobTo3DArray, jpegBlobToBase64 } from "./util";

const handleAltSubmit = async (route, inputs) => {
    if (route === "MultipleLinearRegression")
        return handleMultipleLinearRegression(route, inputs)
    if (route === "card-classification")
        return await handleCardClassification(route, inputs)
}

const handleMultipleLinearRegression = (route, inputs) => {
    console.log(inputs)
    let w = [40.03843741, -5.80476007, 162.28636313, 117.37340334,
        38.41629773, 24.90309153, -100.43159014, 99.58161206,
        149.32726468, 92.21994613];
    let b = 152.13348416289583;
    let res = 0;
    let w_index = 0;
    for (let key in inputs) {
        res += w[w_index] * inputs[key].value;
        w_index++;
    }
    res += b;
    return {
        "status" : "200",
        "data" : {
            "disease progression": res
        }
    };
}

const handleCardClassification = async (route, inputs) => {
    let b64img;
    let url;
    let postData;
    try {
    b64img = await jpegBlobToBase64(inputs["Card Image"]["value"])

    url = "https://ml-api.kailauapps.com/" + route
    postData = {
        "b64img": b64img
    }
    } catch {
        return {
            'status' : 400,
            'data' : 'Image not found.'
        };
    }
    try {
        // Make the API request
        const response = await axios.post(url, postData);
        return {
            "status" : '200',
            "data" : response.data
        };
    } catch (error) {
        return { 
            'status': '400',
            'data' : error
        };
    }
}

export { handleAltSubmit };