import React from "react";
import { useNavigate } from "react-router-dom";

// This component is used to display each ml model onto a card on the homepage
function ModelDisplayCard({ modelInfo }) {
    const navigate = useNavigate();
    const onClickCard = () => {
        navigate(modelInfo.route);
      }

    return (
        <div className="model-card" onClick={onClickCard}>
            <h2 style={{textDecoration: "underline"}}>{modelInfo.displayName}</h2>
            <p className="model-description">{modelInfo.description}</p>

            {modelInfo.image && (
                <img className="model-card-image" src={modelInfo.image} alt={"image-"+modelInfo.route}/>
            )}

        </div>
    )
}

export default ModelDisplayCard;