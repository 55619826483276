import React, { useRef, useState } from 'react';

const SliderTextField = ({ inputName, inputData, handleNumberChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur(); // Blur the input to remove focus
    }
  };

  const handleFocus = () => {
    setInputValue(inputData.value);
    setEditing(true);
  };

  const handleBlur = (event) => {
    var targetValue = event.target.value;
    if (inputData.type === 'integer') {
        targetValue = parseInt(targetValue);
    } else {
        targetValue = parseFloat(targetValue).toFixed(inputData.decimalPlaces);
    }
    handleNumberChange(inputName, inputData, targetValue);
    setEditing(false);
  };

  const handleInputChange = (event) => {
    if (editing) {
      setInputValue(event.target.value);
    }
  };

  return (
    <input
    className='light slider-text-input'
      type="text"
      ref={inputRef}
      value={editing ? inputValue : inputData.value} // Switch between controlled and uncontrolled
      onChange={handleInputChange}
      onFocus={handleFocus}
      onKeyUp={handleKeyPress}
      onBlur={handleBlur}
    />
  );
};

export default SliderTextField;
