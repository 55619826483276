// resize image to specified size
const resizeImage = (file, inputData) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const newWidth = inputData.width;
                const newHeight = inputData.height;

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type || 'image/jpeg', 1);
            };

            img.onerror = (error) => {
                reject(error);
            };
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};

const jpegBlobToBase64 = (imgBlob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result.split(',')[1]; // Remove the data:image/jpeg;base64, prefix
            resolve(base64Image);
        };

        reader.onerror = () => {
            reject("Error reading the image blob");
        };

        reader.readAsDataURL(imgBlob);
    });
};


export { resizeImage, jpegBlobToBase64 };