import { textInput, imageInput, integerInput, floatInput, timelineExperience } from './jsonBuilders';
import dogBreedEvals from "../../media/dog-breed-evals.png";
import multlinreg from "../../media/multlinreg.png";
import cardClassification from "../../media/card-classification.png";

// experience timeline to be displayed under My Experience
const experienceTimeline = [
    timelineExperience(
        "B.S Computer Science",
        "California Baptist University",
        "Graduation May 2024",
        "I am currently a Senior CS student at California Baptist University (CBU). As displayed below, CBU has introduced me to several valuable experiences including Tutoring, Database Management (for the College of Engineering database), and Research. After I achieve a B.S. in CS at CBU, I plan to pursue a PhD in the domain of *machine learning*!"),
    timelineExperience("CBU Ai ML Lab Researcher",
        "California Baptist University",
        "Sep 2022 - Present",
        "During my time in this lab, I applied tools like *Pandas* to process over 13 million patent records into 17 thousand located in the inland empire. I then cross-referenced a dataset of spatial coordinates and displayed the processed data into ArcGIS. Moving forward, we plan to apply *natural language processing* to categorize patents based on their text data."),
    timelineExperience("NREIP Software Engineer Intern",
        "Naval Surface Warfare Center Corona",
        "Jun 2023 - Aug 2023",
        "As a Software Engineer at NSWC Corona, I spent 10 weeks building an end-to-end app for the research proposal workflow at a multi-million dollar Naval Postgraduate School program called CAMRE (Consortium for Additive Manufacturing Research and Education). I designed pages for researchers to submit proposals, for a project manager to pre-screener proposals, and for committee members to make the final decisions on proposals. Tools I used consisted of the *Microsoft Power Platform*, which includes: Power Apps, Power Automate, Power BI, and Power Query."),
    timelineExperience(
        "Full-Stack Carpool Mobile App",
        "California Baptist University",
        "Jan 2023 - Apr 2023",
        "As a member of a 6-student team, I contributed to the development of a mobile app aimed at streamlining carpool coordination. In this project, we leveraged *Flutter* and *Firebase* to create a seamless user experience, in which I took charge of the back-end responsibilities. I designed a robust database model and established the integration of Firebase into the Flutter app. We regularly presented our progress in bi-weekly sprints and successfully pitched the final product at an engineering stand at CBU."
    ),
    timelineExperience("Database Management",
        "California Baptist University",
        "Sep 2022 - Dec 2022",
        "In this role, I gained proficiency in writing queries and manipulating the CBU College of Engineering student database. I familiarized myself with technologies like *Microsoft Access* to create user-friendly forms for CRUD operations on the database. I also utilized *SQL* for structured database querying. Additionally, I developed a *Python*-based ETL process that extracts and structures data from PDF forms, generating an Excel sheet to be loaded into Access."),
    timelineExperience("Java Tutor",
        "California Baptist University",
        "Sep 2022 - Dec 2022",
        "As a *Java* Tutor, I had the opportunity to strengthen my ability to articulate software concepts in a way that any student could understand. Each session was 2 hours, twice a week, and I assisted up to 10 students at a time. I also had weekly meetings with the course professor to give updates on student progress and plan for the following week.")
]

// skills to be displayed under My Skills
const skills = ["Python", "Java", "JavaScript", "SQL", "Pillow", "Matplotlib", "Pandas", "TensorFlow", "Scikit-learn", "Flask", "Flutter", "React.js", "Express.js", "Docker", "AWS", "Firebase", "MongoDB", "MS Power Platform", "MS Access", "Google Colab", "GitHub"];

// High level definition of each model that should be displayed on the app
const models = [
    {
        displayName: "Multiple Linear Regression from Scratch",
        description: "Multiple Linear Regression implemented from scratch using basic math/calculus and NumPy. This implementation was writter for an educational YouTube video that teaches viewers the math, intuition, and implementation of Multiple Linear Regression from scratch. Try out this model against the Scikit-learn diabetes dataset. Notice this dataset uses strictly quantitative values (no categorical values) by default.",
        externalLinks: [
            {name: "GitHub Notebook", url : "https://github.com/kailau02/machine-learning-from-scratch/blob/main/Multiple-Linear-Regression.ipynb"},
            {name: "YouTube Lecture", url : "https://www.youtube.com/watch?v=fldD6fGmsQE&t=10s"}
        ],
        image: multlinreg,
        route: "MultipleLinearRegression",
        altSubmit : true, // If altSubmit is set to true, upon submission click, the handleAltSubmit function in altSubmit.js will be called for this route
        modelOffline: true,
        inputs: [
            floatInput("Age", -0.2, 0.2, 3),
            floatInput("Sex", -0.2, 0.2, 3),
            floatInput("BMI", -0.2, 0.2, 3),
            floatInput("Avg Blood Pressure", -0.2, 0.2, 3),
            floatInput("Total Serum Cholesterol", -0.2, 0.2, 3),
            floatInput("Low-Density Lipoproteins", -0.2, 0.2, 3),
            floatInput("High-Density Lipoproteins", -0.2, 0.2, 3),
            floatInput("Total Cholesterol", -0.2, 0.2, 3),
            floatInput("Possibility of LTG", -0.2, 0.2, 3),
            floatInput("Blood Sugar Level", -0.2, 0.2, 3),
        ]
    },
    {
        displayName: "Playing Card Classification",
        description: "Playing Card Classification using transfer learning of the EfficientNetV2B0 model in Tensorflow. This multiclass classification model was trained on a sythetically generated dataset of over 250,000 bicycle playing cards using Pillow. While a test dataset was not available, testing via webcam shows promising results as shown on the YouTube link.",
        colabLink: "https://colab.research.google.com/drive/12VAZKK_139JR_6HmDDj1MXtWKUQJst69?usp=sharing",
        externalLinks: [
            {name: "YouTube Test", url : "https://www.youtube.com/watch?v=nuo4zNrWvig"}
        ],
        image: cardClassification,
        route: "card-classification",
        altSubmit: true,
        inputs: [
            imageInput("Card Image", 224, 224)
        ]
    },
    {
        displayName: "Dog Breed Classification",
        description: "Dog Breed Classification using transfer learning of the NASNet Mobile model in Tensorflow. This multiclass classification model was trained on 10222 labeled images and 120 classes of dog breeds. During a train-test split of 90:10, this model achieved an accuracy score of 82.31%. In the full training submission to Kaggle, this model achieved a Multi Class Log Loss score of 0.62358.",
        colabLink: "https://colab.research.google.com/drive/1hxdfyxzvlRA_8fYnTj82jlnbivW6Kv91?usp=sharing",
        externalLinks: [
            {name: "Kaggle Dataset", url : "https://www.kaggle.com/competitions/dog-breed-identification/"}
        ],
        image: dogBreedEvals,
        route: "dog-breeds",
        altSubmit: false,
        inputs: [
            imageInput("Dog Image", 224, 224)
        ]
    },
    {
        displayName: "Tweet Sentiment Analysis",
        description: "Tweet sentiment analysis using Scikit-learn Logistic Regression. This model was trained on ~1.6 million labeled (positive or negative sentiment) tweets. It has acheived a test accuracy of 80%, and a full dataset accuracy of 83%.",
        colabLink: "https://colab.research.google.com/drive/1qhB1VQHj_l2GwVMIK_NHoBfqA0EHZLqo?usp=sharing",
        externalLinks: [
            {name: "Kaggle Dataset", url : "https://www.kaggle.com/datasets/kazanova/sentiment140"},
        ],
        image: null,
        route: "tweet-sentiment",
        altSubmit: false,
        inputs: [
            textInput("Tweet")
        ]
    },
    {
        displayName: "House Price Prediction",
        description: "Boston Housing Price Prediction using Tensorflow. This is a common dataset used for making regression predictions of house prices given 13 numerical features. As noted on the Kaggle page, the target label represents \"Median value of owner-occupied homes in $1000s\". This trained neural network model achieved an MSE score of ~26.",
        colabLink: "https://colab.research.google.com/drive/1pOWrMxB9AxT8pVWg5BZ8UH0BtpL3XewH?usp=sharing",
        externalLinks: [
            {name: "Kaggle Dataset", url : "https://www.kaggle.com/code/prasadperera/the-boston-housing-dataset/input"}
        ],
        image: null,
        route: "house-prices",
        altSubmit: false,
        inputs: [
            floatInput("Crime",         0.006,      88.976,     3),
            floatInput("Zoning",        0,          100,        3),
            floatInput("Industry",      0.46,       27.74,      2),
            integerInput("River",       0,          1),
            floatInput("NitricOxide",   0.385,      0.871,      3),
            floatInput("Rooms",         3.561,      8.725,      3),
            floatInput("Age",           2.9,        100,        1),
            floatInput("Distance",      1.13,       10.710,     3),
            integerInput("Access",        1,        24),
            integerInput("Tax",         188,        711),
            floatInput("Ratio",         12.6,       22,         1),
            floatInput("Blacks",        0.32,       396.9,      2),
            floatInput("Status",        1.73,       37.97,      2)
        ]
    },
]

export { experienceTimeline, skills, models };